
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































.news__grid {
  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding-right: col(3, 20);

    > .news__card {
      width: col(5, 17);
      margin-bottom: 12rem;
    }
  }
}

.news__grid__action {
  display: flex;
  width: 100%;

  @include mq($until: m) {
    justify-content: center;
    margin-top: 11rem;
  }

  @include mq(m) {
    margin-top: -5rem;

    ::v-deep .cta__btn {
      margin-left: auto;
    }
  }
}
