
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















































































































































.hero {
  display: flex;
  flex-direction: column-reverse;
}

.hero-wrapper {
  z-index: 1;
  margin-top: -3rem;
  padding-top: 0;

  @include mq(m) {
    margin-top: -6.5rem;
  }
}

.hero__content {
  max-width: 120rem;
  margin: auto;
  text-align: center;
}

.hero__content__title {
  @include fluid(
    font-size,
    (
      s: 42px,
      l: 64px,
      xl: 100px,
    )
  );
  @include fluid(
    line-height,
    (
      s: 44px,
      l: 64px,
      xl: 100px,
    )
  );
}

.hero__content__baseline {
  margin: auto;
  color: $c-blue;

  @include mq(m) {
    width: col(15, 24);
  }
}

.hero__content__reading-time {
  @include fluid(
    font-size,
    (
      s: 13px,
      xl: 15px,
    )
  );

  color: $c-green;
  font-weight: 500;
  line-height: 1.3;
}

.test {
  margin-inline: auto;
  position: relative;
  width: 100%;
  max-width: 168rem;
  height: 40rem;
  margin-top: 0;

  img {
    @include image-fit(cover);
  }

  @include mq(m) {
    height: 60rem;
  }
}

.hero__picture-outer {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
